<template>
    <div class='bodyAnalysis'>
        <div class="bodyContent" v-for="(item, index) in bodyData" :key="index">
            <div class='analysisHeader'>
                <div class="iconimg">
                    <img class="img" :src="iconimg" alt="">
                </div>
                <div class="Title">
                    <div class="text">{{item.title}}</div>
                    <div class="text">ANALYSIS OF SYSTEMIC ORGAN FUNCTION AND RESERVE CAPACITY</div>
                </div>
                <div class="waveImg">
                    <img  class="img" :src="waveImg" alt="">
                </div>
            </div>
            <div class="content">
                <div class="head">
                    主靶向器官及问题——{{item.subtitle}}
                </div>
                <div class="body">
                    <img class="img" :src="body">
                    <div class="left">
                        <div class="title" :ref="`secondary${index}`">副靶向器官/问题</div>
                        <div class="diseaseArr">
                            <div class="disease" :ref="`vice${y}${index}`" v-for="(val, y) in item.secondary" :key="y" >
                                <div class="diseaseItem" :style="{'backgroundColor': val.bagColor}">
                                    <div>{{val.title}}</div>
                                    <div class="itemContent" v-if="val.content.length > 0">
                                        <div v-for="(el,i) in val.content" :key="i">{{el.showIndex}}</div>
                                    </div>
                                </div>
                                <div class="bar" :style="{'height': `${val.barHeight}px`,'top': val.top,'bottom': val.bottom}"></div>
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="title" :ref="`factorFa${index}`">相关因素</div>
                        <div class="diseaseArr">
                            <div class="disease" :ref="`factor${y}${index}`" v-for="(val, y) in item.factors" :key="y" >
                                <div class="diseaseItem" :style="{'backgroundColor': val.bagColor}">
                                    <div>{{val.title}}</div>
                                    <div class="itemContent" v-if="val.content.length > 0">
                                        <div v-for="(el,i) in val.content" :key="i">{{el.showIndex}}</div>
                                    </div>
                                </div>
                                <div class="bar" :style="{'height': `${val.barHeight}px`,'top': val.top,'bottom': val.bottom}"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import { GetMatrixDataByUserId } from '@/api/index'
import { sortArr, unique } from '@/util/validate'

export default {
    components: {
        
    },
    data() {
        return {
            title: '内分泌及相关因素风险分析',
            logo: `${this.$imgDomain}/www/HealthingCore/bodylogo.png`,
            body: `${this.$imgDomain}/www/HealthingCore/bodymap.png`,
            iconimg: `${this.$imgDomain}/www/HealthingCore/icon11.png`,
            waveImg: `${this.$imgDomain}/www/HealthingCore/icon6.png`,            
            bodyData: [],
        };
    },
    props:{
        
    },
    created() {
        
    },
    mounted() {
        this.getMatrixDataList();
    },
    methods: {
        getMatrixDataList(){
            const userId = this.$query('userId');
            GetMatrixDataByUserId({userId}).then(res => {
                const {data} = res;
                if(data){
                    data.forEach(item =>{
                        if(item.title.indexOf('荷尔蒙') != -1){
                            item.subtitle = '荷尔蒙及相关因素分析';
                            item.title = '荷尔蒙';
                        } else {
                            item.subtitle = item.title.split('及')[0];
                        }

                        item.content.map(el => {
                            if(el.caseValue.indexOf('%') != -1){
                                el.percentage = Math.abs(Number(el.caseValue.split('%')[0]));
                            }
                        })
                        const sortData = sortArr(item.content, 'matrixType');
                        sortData.forEach(vla => {
                            const uniqueData = sortArr(vla, 'projectName');
                            const newData = [];
                            uniqueData.map(el => {
                                newData.push(unique(el, 'showIndex'))
                            })
                            
                            if(newData[0][0].matrixType.indexOf('副靶向器官') != -1){
                                item.secondary = newData;
                            } else {
                                item.factors = newData;
                            }          
                        })
                    })

                    // 二次处理数据
                    this.secondaryTreatment(data);
                }
            })
        },
        secondaryTreatment(data){
            const newData = [];
            data.forEach(item => {
                const everyEdition = {
                    title: item.title,
                    subtitle: item.subtitle,
                    secondary: [],
                    factors: []
                };
                // 副靶向数据处理
                item.secondary.forEach(el => {
                    const heanldData = {};
                    // 筛选病理值0.9以下的
                    const filterArr = el.filter(ops => {
                        heanldData.title = ops.projectName
                        return ops.caseValue < 0.9
                    })
                    // 个别数据处理
                    if(heanldData.title == '瘦弱' || heanldData.title == '肥胖'){
                        heanldData.content = [];
                    } else {
                        heanldData.content = filterArr;
                    }

                    // 程度颜色
                    if(heanldData.content.length > 0) {
                        const minMathData = Math.min.apply(Math, filterArr.map(key => key.caseValue));
                        const sclerodItme = filterArr.find(key => minMathData == key.caseValue);
                        if(sclerodItme.caseValue <= 0.425){
                            heanldData.bagColor = '#A12924';
                        } else if (0.425 < sclerodItme.caseValue && sclerodItme.caseValue < 0.9) {
                            heanldData.bagColor = '#E8E080';
                        } else {
                            heanldData.bagColor = '#71A241';
                        }
                    } else {
                        heanldData.bagColor = '#71A241';
                    }

                    everyEdition.secondary.push(heanldData);
                })

                // 相关因素数据处理
                if(item.factors){
                    // 相关因素
                    item.factors.forEach(el => {
                        const heanldData = {};

                        const filterArr = el.filter((ops, index) => {
                            heanldData.title = ops.projectName
                            if(ops.percentage) {
                                if(index < 3){
                                    return ops.percentage > 35
                                }
                            } else {
                                return ops.caseValue < 0.9
                            }
                        })
                        heanldData.content = filterArr;

                        if(heanldData.content.length > 0){
                            heanldData.bagColor = ''
                            const minMathData = Math.min.apply(Math, filterArr.map(key => key.caseValue));
                            const sclerodItme = el.find(key => minMathData == key.caseValue);
                            if(sclerodItme) {
                                if(sclerodItme.caseValue <= 0.425){
                                    heanldData.bagColor = '#A12924';
                                } else if (0.425 < sclerodItme.caseValue && sclerodItme.caseValue < 0.9) {
                                    heanldData.bagColor = '#E8E080';
                                } else {
                                    heanldData.bagColor = '#71A241';
                                }
                            } else {
                                const minMathDataNum = Math.min.apply(Math, filterArr.map(key => key.percentage));
                                const sclerodItmeNum = el.find(key => minMathDataNum == key.percentage);
                                if(sclerodItmeNum.percentage > 35){
                                    heanldData.bagColor = '#E8E080';
                                } else {
                                    heanldData.bagColor = '#71A241';
                                }
                            }
                        } else {
                            heanldData.bagColor = '#71A241'
                        }

                        everyEdition.factors.push(heanldData);
                    })
                }
                
                newData.push(everyEdition)
            })
            this.bodyData = newData;
            this.$nextTick(() => {
                this.domDistanceProcessing(this.bodyData);
            })
        },
        domDistanceProcessing(data){
            data.map((item, i) => {
                const secondaryTop = this.$refs[`secondary${i}`][0].offsetTop + 75;
                const factorFaTop = this.$refs[`factorFa${i}`][0].offsetTop + 75;
                // 副靶向数据
                item.secondary.forEach((item,index) => {
                    let calculated = 0;
                    const viceItemTop = this.$refs[`vice${index}${i}`][0].offsetTop + 22;
                    if(viceItemTop < secondaryTop) {
                        // 在中心线上方
                        item.top = '22px';
                        calculated = secondaryTop - viceItemTop;
                    } else {
                        // 在中心线下方 或者 中间
                        item.bottom = '22px';
                        calculated = viceItemTop - secondaryTop;
                    }
                    item.barHeight = Math.abs(calculated)
                })
                // 相关因素处理
                item.factors.forEach((item,index) => {
                    let calculated = 0;
                    const factorsTop = this.$refs[`factor${index}${i}`][0].offsetTop+ 22;
                    if(factorsTop < factorFaTop) {
                        // 在中心线上方
                        item.top = '22px';
                        calculated = factorFaTop - factorsTop;
                    } else {
                        // 在中心线下方 或者 中间
                        item.bottom = '22px';
                        calculated = factorsTop - factorFaTop;
                    }
                    item.barHeight = Math.abs(calculated)
                })
            })
            
            this.$forceUpdate();
        }
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>
.bodyAnalysis {
    height: 100%;

    .bodyContent {
        margin-top: 20px;
        .analysisHeader {
            min-height: 50px;
            width: 100%;
            border-radius: 15px 15px 0 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            overflow: hidden;
            color: #fff;
            background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/bag3.png');
            background-repeat:no-repeat;
            background-position:center;
            .iconimg {
                width: 80px;
            }
            .waveImg {
                width: 30px;
                height: 30px;
            }
            .Title {
                width: calc(100% - 130px);
                padding-left: 10px;
                border-left: 2px solid #fff;
                margin: 0 10px;
                .text {
                    overflow:hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
        .content {
            min-height: 600px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #F5F5F5;
            border-radius: 0 0 15px 15px;
            margin-top: 5px;
            position: relative;
            .head {
                width: 90%;
                height: 50px;
                position: absolute;
                top: 5%;
                background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/bag3.png');
                background-repeat:no-repeat;
                background-position:center;
                font-size: 18px;
                color: #585757;
                font-weight: 700;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .body {
                width: 22%;
                position: relative;
                .left,
                .right{
                    height: 470px;
                    position: absolute;
                    bottom: -50%;
                    display: flex;
                    align-items: center;
                    .title {
                        height: 150px;
                        width: 20px;
                        background: #585757;
                        color: #fff;
                        writing-mode:vertical-rl;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 3px;
                        z-index: 999;
                        font-size: 14px;
                    }
                    .diseaseArr {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        height: 100%;
                        .disease {
                            position: relative;
                        }
                        .diseaseItem {
                            width: 45px;
                            height: 45px;
                            border-radius: 25px;
                            color: #fff;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            flex-wrap: wrap;
                            position: relative;
                            .itemContent {
                                width: 70px;
                                text-align: start;
                                position: absolute;
                                color: #585757;
                                border: 1px dashed #585757;
                                padding: 0 3px;
                            }
                        }
                        .bar {
                            position: absolute;
                            width: 1px;
                            background: #585757;
                        }
                        
                    }
                }
                .left {
                    left: -80px;
                    flex-direction: row-reverse;
                    .title {
                        margin-left: 10px;
                    }
                    .diseaseArr {
                        .diseaseItem::after{
                            content: "";
                            width: 22px;
                            height: 1px;
                            background: #585757;
                            position: absolute;
                            right: -22px;
                        }
                        .diseaseItem {
                            .itemContent {
                                left: -75px;
                            }
                        }
                        .bar {
                            right: -22px;
                        }
                    }
                }
                .right {
                    right: -80px;
                    .title {
                        margin-right: 10px;
                    }
                    .diseaseArr {
                        .diseaseItem::after{
                            content: "";
                            width: 22px;
                            height: 1px;
                            background: #585757;
                            position: absolute;
                            left: -22px;
                        }
                        .diseaseItem {
                            .itemContent {
                                right: -75px;
                            }
                        }
                        .bar {
                            left: -22px;
                        }
                    }
                }
            }
        }
    }
    .bodyContent:first-child{
        margin-top: 0;
    }
}
</style>